import { storeModelConfig } from "@vueda/stores/storeModelConfig.js";

export function setupModelConfig(pinia) {
    const modelConfigStore = storeModelConfig(pinia);

    // setup client side model config, in some cases overriding and others
    //  supplementing the server side config
    modelConfigStore.setConfig(
        { app: "tim", model: "timesheet" },
        {
            actions: {
                list: ["Employee", "Timesheet Admin", "Timesheet Approver", "HR", "HR Management"],
                create: ["Employee", "Timesheet Admin", "Timesheet Approver", "HR", "HR Management"],
                update: ["Employee", "Timesheet Admin", "Timesheet Approver", "HR", "HR Management"],
                destroy: ["Timesheet Admin"],
                submit: ["Employee", "Timesheet Admin", "Timesheet Approver", "HR", "HR Management"],
                approve: ["Timesheet Approver", "HR", "HR Management"],
                "undo-approve": ["Timesheet Approver", "HR", "HR Management"],
                retract: ["Employee", "Timesheet Admin", "Timesheet Approver", "HR", "HR Management"],
            },
            filterableDetails: {
                supervisor__in: {
                    ignorelookupExprs: true,
                },
            },
        },
        {
            list: {
                fetchFields: [
                    "week_start",
                    "employee",
                    "employee__id",
                    "employee__formatted_name",
                    "is_active",
                    "workflow_state_code",
                    "workflow_state_name",
                    "cannot_edit",
                ],
                displayFields: ["update", "week_start", "employee", "is_active", "workflow_state"],
                fieldDetails: {
                    employee: {
                        value: "employee.id",
                        formatted: "employee.formatted_name",
                    },
                },
                expands: ["employee"],
            },
            create: {
                fields: ["employee", "week_start", "as_vacation"],
                expands: [],
                fieldComponents: {
                    week_start: "FieldDate",
                },
                widgetComponents: {
                    week_start: "WidgetDatePicker",
                },
                formProps: {
                    class: "flex flex-col sm:flex-row gap-1 md:gap-2 2xl:gap-4 items-stretch flex-wrap",
                    themeOverride: {
                        FormModel: {
                            field: {
                                class: "bg-zinc-50 dark:bg-zinc-900 rounded-md p-1 lg:p-2 2xl:p-3 self-stretch",
                            },
                        },
                    },
                },
                fieldProps: {
                    as_vacation: {
                        help: "Prefill the timesheet with the expected level of vacation time for a week. Available if selected employee is not working casually.",
                        // align label-less boolean with week_start, so the inputs line up
                        class: "shrink-0 min-w-max mb-[3px] flex",
                        themeOverride: {
                            FormModel: {
                                fieldInner: {
                                    class: "place-self-end",
                                },
                            },
                        },
                    },
                    employee: {
                        dependents: ["as_vacation"],
                        class: "shrink-0 min-w-[35ch]",
                    },
                    week_start: {
                        class: "shrink-0",
                    },
                },
                widgetProps: {
                    employee: {
                        extraListArgs: {
                            is_employee: true,
                        },
                    },
                },
            },

            retrieve: {
                submitFields: ["week_start", "employee", "rejected_time", "timesheet_days", "timesheet_tasks"],
                displayFields: [
                    "week_start",
                    "employee",
                    "bank_summary",
                    "overtime_gained",
                    "total_payable_hours",
                    "timesheet_days",
                    "timesheet_tasks",
                    "timesheet_task_project_summaries",
                ],
                fetchFields: [
                    "workflow_state_code",
                    "workflow_state_name",
                    "week_start",
                    "employee",
                    "overtime_gained",
                    "overtime_gained_help",
                    "total_payable_hours",
                    "bank_summary",
                    "anniversary",
                    "rejected_time",
                    "timesheet_days",
                    "timesheet_days__start",
                    "timesheet_days__total_worked",
                    "timesheet_days__day",
                    "timesheet_days__end",
                    "timesheet_days__lunch",
                    "timesheet_tasks",
                    "timesheet_tasks__project",
                    "timesheet_tasks__day",
                    "timesheet_tasks__task_code",
                    "timesheet_tasks__hours",
                    "timesheet_tasks__quantity",
                    "timesheet_tasks__description",
                    "timesheet_task_project_summaries",
                    "cannot_edit",
                ],
                computedFields: ["timesheet_days__total_worked"],
                expands: [
                    "timesheet_days",
                    "timesheet_tasks",
                    "timesheet_task_project_summaries",
                    "bank_summary",
                    "anniversary",
                ],
                fieldComponents: {
                    timesheet_days: "FieldObject",
                    timesheet_tasks: "FieldObject",
                    timesheet_task_project_summaries: "FieldObject",
                    week_start: "FieldDate",
                    bank_summary: "FieldObject",
                },
                widgetComponents: {
                    week_start: "WidgetReadOnly",
                    employee: "WidgetReadOnly",
                },
                formProps: {
                    class: "grid 4xl:grid-cols-9 xl:grid-cols-4 2xs:grid-cols-2 gap-2 md:gap-3 2xl:gap-4 4xl:gap-6 pt-1",
                },
                fieldProps: {
                    week_start: {
                        class: "4xl:row-start-1",
                    },
                    employee: {
                        class: "4xl:row-start-1",
                    },
                    overtime_gained: {
                        class: "max-2xs:row-start-3 2xs:max-xl:row-start-2 4xl:row-start-2",
                    },
                    total_payable_hours: {
                        class: "max-2xs:row-start-4 2xs:max-xl:row-start-2 4xl:row-start-2",
                    },
                    bank_summary: {
                        class: "2xs:col-span-2 xl:row-span-2 xl:col-start-3 xl:col-end-5 4xl:row-span-2 4xl:col-start-1 4xl:col-end-3",
                    },
                    timesheet_days__lunch: {
                        help: "The time taken for lunch, in hours. This is subtracted from the total worked hours.",
                    },
                    timesheet_tasks: {
                        class: [
                            // full width for the inline form
                            "xl:col-span-4 2xs:col-span-2 4xl:col-span-9",
                        ],
                        themeOverride: {
                            ObjectsGrid: {
                                bodyRowGroup: {
                                    // more cards per row
                                    class: "sm:grid-cols-2 2xl:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-5",
                                },
                                cardContainer: {
                                    // display headers beside values
                                    class: "max-w-full grid grid-cols-[minmax(min-content,max-content)_minmax(0px,1fr)]",
                                },
                            },
                        },
                    },
                    timesheet_days: {
                        class: [
                            // full width for the inline form
                            "xl:col-span-4 2xs:col-span-2 4xl:col-span-9",
                        ],
                        themeOverride: {
                            ObjectsGrid: {
                                bodyRowGroup: {
                                    // more cards per row
                                    class: "sm:grid-cols-2 2xl:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-5",
                                },
                                cardContainer: {
                                    // display headers beside values
                                    class: "max-w-full grid grid-cols-[minmax(min-content,max-content)_minmax(0px,1fr)]",
                                },
                            },
                        },
                    },
                    timesheet_task_project_summaries: {
                        class: [
                            // full width for the inline form
                            "xl:col-span-4 2xs:col-span-2 4xl:col-span-9",
                        ],
                        themeOverride: {
                            ObjectsGrid: {
                                bodyRowGroup: {
                                    // more cards per row
                                    class: "sm:grid-cols-2 2xl:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-5",
                                },
                                cardContainer: {
                                    // display headers beside values
                                    class: "max-w-full grid grid-cols-[minmax(min-content,max-content)_minmax(0px,1fr)]",
                                },
                            },
                        },
                    },
                },
                widgetProps: {
                    timesheet_tasks__task_code: {
                        modelExpandFields: ["category"],
                    },
                    timesheet_tasks: {
                        cardHeaderClasses: {
                            day: "hidden",
                        },
                        cardFieldClasses: {
                            day: "col-span-2 mb-2",
                        },
                    },
                    timesheet_days: {
                        cardHeaderClasses: {
                            day: "hidden",
                        },
                        cardFieldClasses: {
                            day: "col-span-2 mb-2",
                        },
                    },
                    timesheet_task_project_summaries: {
                        cardHeaderClasses: {
                            project: "self-center",
                        },
                        cardFieldClasses: {
                            project: "tabular-numbers",
                        },
                    },
                },
                fieldDetails: {
                    timesheet_days: {
                        formatted: "related.employee.full_name",
                    },
                },
            },
            update: {
                submitFields: ["week_start", "employee", "rejected_time", "timesheet_days", "timesheet_tasks"],
                displayFields: [
                    "week_start",
                    "employee",
                    "bank_summary",
                    "overtime_gained",
                    "total_payable_hours",
                    "timesheet_days",
                    "timesheet_tasks",
                    "timesheet_days__start",
                    "timesheet_days__total_worked",
                    "timesheet_days__day",
                    "timesheet_days__end",
                    "timesheet_days__lunch",
                    "timesheet_tasks__destroy",
                    "timesheet_tasks__clone",
                    "timesheet_tasks__project",
                    "timesheet_tasks__day",
                    "timesheet_tasks__task_code",
                    "timesheet_tasks__hours",
                    "timesheet_tasks__quantity",
                    "timesheet_tasks__description",
                    "timesheet_task_project_summaries",
                ],
                fetchFields: [
                    "workflow_state_code",
                    "workflow_state_name",
                    "week_start",
                    "employee",
                    "overtime_gained",
                    "overtime_gained_help",
                    "total_payable_hours",
                    "bank_summary",
                    "anniversary",
                    "rejected_time",
                    "timesheet_days",
                    "timesheet_tasks",
                    "timesheet_task_project_summaries",
                    "cannot_edit",
                ],
                computedFields: ["timesheet_days__total_worked"],
                expands: [
                    "timesheet_days",
                    "timesheet_tasks",
                    "timesheet_task_project_summaries",
                    "bank_summary",
                    "anniversary",
                ],
                fieldComponents: {
                    timesheet_days: "FieldSetTabularInline",
                    timesheet_tasks: "FieldSetTabularInline",
                    timesheet_task_project_summaries: "FieldObject",
                    week_start: "FieldDate",
                    bank_summary: "FieldObject",
                },
                widgetComponents: {
                    week_start: "WidgetReadOnly",
                    employee: "WidgetReadOnly",
                    timesheet_days__day: "WidgetReadOnly",
                },
                formProps: {
                    class: "grid 4xl:grid-cols-9 xl:grid-cols-4 2xs:grid-cols-2 gap-2 md:gap-3 2xl:gap-4 4xl:gap-6 pt-1",
                },
                fieldProps: {
                    week_start: {
                        class: "4xl:row-start-1",
                    },
                    employee: {
                        class: "4xl:row-start-1",
                    },
                    overtime_gained: {
                        class: "max-2xs:row-start-3 2xs:max-xl:row-start-2 4xl:row-start-2",
                    },
                    total_payable_hours: {
                        class: "max-2xs:row-start-4 2xs:max-xl:row-start-2 4xl:row-start-2",
                    },
                    bank_summary: {
                        class: "2xs:col-span-2 xl:row-span-2 xl:col-start-3 xl:col-end-5 4xl:row-span-2 4xl:col-start-1 4xl:col-end-3",
                    },
                    timesheet_days: {
                        class: [
                            // full width for the inline form
                            "2xs:col-span-2 xl:col-span-4 4xl:row-span-4 4xl:row-start-1 4xl:col-span-5",
                        ],
                        cardHeaderClasses: {
                            timesheet_days__day: "hidden",
                        },
                        cardFieldClasses: {
                            timesheet_days__day: "col-span-2 mb-2",
                            timesheet_days__total_worked: "pl-3.5",
                        },
                        tableBreakpoint: "xl",
                        showCreateButton: false,
                        themeOverride: {
                            ObjectsGrid: {
                                bodyRowGroup: {
                                    // more cards per row
                                    class: "md:grid-cols-2 2xl:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-5",
                                },
                                cardContainer: {
                                    // display headers beside values
                                    class: "max-w-full grid grid-cols-[minmax(min-content,max-content)_minmax(0px,1fr)]",
                                },
                            },
                        },
                    },
                    timesheet_days__lunch: {
                        help: "The time taken for lunch, in hours. This is subtracted from the total worked hours.",
                    },
                    timesheet_days__day: {
                        themeOverride: {
                            WidgetReadOnly: {
                                input: {
                                    class: {
                                        "ml-3": false,
                                    },
                                },
                            },
                        },
                    },
                    timesheet_tasks: {
                        class: [
                            // full width for the inline form
                            "xl:col-span-4 2xs:col-span-2 4xl:col-span-9",
                        ],
                        tableBreakpoint: "2xl",
                        tableHeaderClasses: {
                            "item-action-bar": "4xl:w-[15rem]",
                        },
                        tableFieldClasses: {},
                        cardHeaderClasses: {
                            "item-action-bar": "hidden",
                        },
                        cardFieldClasses: {
                            "item-action-bar": "col-span-2",
                        },
                        themeOverride: {
                            ObjectsGrid: {
                                bodyRowGroup: {
                                    // more cards per row
                                    class: "md:grid-cols-2 2xl:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-5",
                                },
                                cardContainer: {
                                    // display headers beside values
                                    class: "max-w-full grid grid-cols-[minmax(min-content,max-content)_minmax(0px,1fr)]",
                                },
                            },
                            ObjectsGridCardCell: {
                                header: {
                                    class: "pt-[0.666rem]",
                                },
                            },
                        },
                    },
                    timesheet_task_project_summaries: {
                        class: [
                            // full width for the inline form
                            "xl:col-span-4 2xs:col-span-2 4xl:col-span-9",
                        ],
                        themeOverride: {
                            ObjectsGrid: {
                                bodyRowGroup: {
                                    // more cards per row
                                    class: "sm:grid-cols-2 2xl:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-5",
                                },
                                cardContainer: {
                                    // display headers beside values
                                    class: "max-w-full grid grid-cols-[minmax(min-content,max-content)_minmax(0px,1fr)]",
                                },
                            },
                        },
                    },
                },
                widgetProps: {
                    timesheet_days__start: {
                        stepMinute: 15,
                    },
                    timesheet_days__end: {
                        stepMinute: 15,
                    },
                    timesheet_days__lunch: {
                        min: 0,
                        max: {
                            h: 24,
                            m: 1440,
                        },
                        step: {
                            h: 0.25,
                            m: 15,
                        },
                        type: "number",
                        minFractionDigits: 2,
                        maxFractionDigits: 2,
                    },
                    timesheet_tasks__hours: {
                        min: 0,
                        max: 24,
                        step: 0.25,
                        type: "number",
                        minFractionDigits: 2,
                        maxFractionDigits: 2,
                    },
                    timesheet_tasks__project: {
                        selectedOptionLabel: "number",
                        modelFields: ["id", "formatted_name", "number", "number_int", "mobilize_bill_method"],
                    },
                    timesheet_tasks__task_code: {
                        modelExpandFields: ["category"],
                        isLazy: false,
                        grouped: true,
                        groupBy: "category",
                        selectedOptionLabel: "description",
                    },
                    timesheet_task_project_summaries: {
                        cardHeaderClasses: {
                            project: "self-center",
                        },
                        cardFieldClasses: {
                            project: "tabular-numbers",
                        },
                    },
                },
                fieldDetails: {
                    timesheet_days: {
                        formatted: "related.employee.full_name",
                    },
                },
                expandDetails: {
                    timesheet_days: {
                        f: {
                            total_worked: {
                                value: "calculated.total_worked",
                                label: "Total Worked",
                            },
                        },
                        hidden: ["id", "timesheet"],
                    },
                    timesheet_tasks: {
                        f: {
                            clone: {
                                label: "Clone",
                                action: true,
                            },
                            destroy: {
                                label: "Destroy",
                                action: true,
                            },
                        },
                        hidden: ["id", "timesheet"],
                    },
                },
            },
        },
    );
}
